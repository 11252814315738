<template>
  <div class="Page relative">
    <div
      v-editable="blok"
      class="w-full overflow-hidden"
    >
      <div class="topMarginReference" />
      <AutoBreadcrumbs v-if="!blok.hideBreadcrumbs" />
      <SpaceHandler
        v-for="(childBlok, index) in blok.body"
        :key="childBlok._uid"
        :blok="childBlok"
      >
        <!-- IMPORTANT: Blocks using async data needs to be loaded manually here -->
        <ArticleListing
          v-if="childBlok.component === 'ArticleListing'"
          :blok="childBlok"
          :placement="index"
        />
        <ProductList
          v-else-if="childBlok.component === 'ProductList'"
          :blok="childBlok"
          :placement="index"
        />
        <EditorialProductBlock
          v-else-if="childBlok.component === 'EditorialProductBlock'"
          :blok="childBlok"
          :placement="index"
        />
        <StoryblokComponent
          v-else
          :blok="childBlok"
          :placement="index"
        />
      </SpaceHandler>
    </div>
  </div>
</template>

<script setup lang="ts">
import { StoryBlokPropBase, StoryBlokSeoPlugin } from '~/constants/types/storyblok';
import { SbBlokData } from '@storyblok/vue';
import SpaceHandler from '~/components/body/SpaceHandler.vue';
import AutoBreadcrumbs from '~/components/body/AutoBreadcrumbs.vue';
import ProductList from '~/components/product/ProductList.vue';
import useSeoCreator from '~/composeables/useSeoCreator';

export type StoryBlokPageContent = StoryBlokPropBase<'page'> & {
  body: SbBlokData[],
  hideBreadcrumbs: boolean;
  seo: StoryBlokSeoPlugin;
  //excludeSibling: boolean;
  excludeSitemap: boolean;
}

const props = defineProps<{
  blok: StoryBlokPageContent,
}>();
if (props.blok.seo) {
  useSeoCreator().createMeta(props.blok.seo);
}
</script>

<style lang="postcss">

</style>
